.App {
  font-family: 'Exo 2', sans-serif;
  text-align: center;
}


body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Exo 2', sans-serif;
}

header {
  font-family: 'Exo 2', sans-serif;
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  font-family: 'Exo 2', sans-serif;
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

footer {
  font-family: 'Exo 2', sans-serif;
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  font-family: 'Exo 2', sans-serif;
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

link {
  font-family: 'Exo 2', sans-serif;
  color: #61dafb;
}


.App-link {
  font-family: 'Exo 2', sans-serif;
  color: #61dafb;
}

div.table{
  font-family: 'Exo 2', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

table, th, td {
  font-family: 'Exo 2', sans-serif;
  border: px solid white;
  border-collapse: collapse;
}

div.tooltip {
    font-family: 'Exo 2', sans-serif;
    position: absolute;
    text-align: center;
    /* width: 60px;
    height: 28px; */
    padding: 5px;
    font: 18px sans-serif;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 8px;
    pointer-events: none;
}


body.light-mode {
  font-family: 'Exo 2', sans-serif;
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #1a1919;
  color: #999;
}

/* cfont {font-family: 'Caveat', cursive;} */
cfont {font-family: 'Exo 2', sans-serif;}

